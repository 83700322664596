import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import './App.scss';
import store from  './Store/store';
import axios from 'axios';
import { BrowserRouter, useNavigate, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {getEncryptedLocalStorage} from "./utlis/HelperFunctions"
let history = createBrowserHistory();

// axios.defaults.baseURL = "http://localhost:4000/api/"
// axios.defaults.baseURL = "http://172.16.16.157:4000/api/"
// axios.defaults.baseURL = "http://43.205.106.109:4000/api/"
// axios.defaults.baseURL = "https://app.centum.academy/api/"; 
axios.defaults.baseURL = "https://staging.centum.academy/api/"; 


// const navigate = useNavigate()

axios.interceptors.request.use(
  (config) => {
    const token = getEncryptedLocalStorage("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      localStorage.clear();
      history.replace("/login");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
    <HistoryRouter history={history}>
      <App />
    </HistoryRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
